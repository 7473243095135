import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { FRONTEND_TITLE } from "../constants";

const LandingLink = ({ className, onClick }) => {
  const { pathname } = useLocation();
  return pathname !== "/" ? (
    <Link className={className} onClick={onClick} to="/">
      {FRONTEND_TITLE}
    </Link>
  ) : (
    <span className={className}>{FRONTEND_TITLE}</span>
  );
};

LandingLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default LandingLink;
