import * as I from "immutable";
import React from "react";
import { Response, useRequest } from "react-sadness";

import Page, { getPageProps } from "../components/Page";
import Talks from "../components/Talks";
import { toTalkRecord } from "../records/Talk";

import ogImage from "../../public/images/ogimage-talks.jpg";

const PAGE_DESCRIPTION = `
Igor Davydenko has giving a lot of talks mostly about Python, asyncio stack,
aiohttp, type annotations, data storages & other web development topics.
`;

const toList = (data) => {
  return new I.List(data.map(toTalkRecord));
};

const TalksPage = () => {
  const { response } = useRequest("/talks", { responseDataConverter: toList });
  return (
    <Page
      {...getPageProps("Talks")}
      description={PAGE_DESCRIPTION}
      hasContainer
      name="talks"
      ogDescription="Check my talks about Python, asyncio stack, type annotations & other topics."
      ogImage={ogImage}
    >
      <Response data={response}>
        {(talks) => {
          console.info({ talks });
          return <Talks data={talks} />;
        }}
      </Response>
    </Page>
  );
};

export default TalksPage;
