import AnchorJS from "anchor-js";
import classnames from "classnames";
import * as I from "immutable";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

import Container from "./Container";
import Project from "./Project";

const Projects = ({ data }) => {
  useEffect(() => {
    new AnchorJS().add("h2.card-title");
  }, [data]);

  return (
    <Fragment>
      {data.map((item, idx) => {
        const isFirst = idx === 0;
        const isLast = idx === data.count() - 1;
        const isOdd = idx % 2 === 1;

        return (
          <div
            className={classnames({
              "mb-5": isFirst,
              "my-5": !isFirst && !isOdd && !isLast,
              "mt-5": !isOdd && isLast,
              "bg-body-tertiary my-5 py-5": isOdd && !isLast,
              "bg-body-tertiary mt-5 py-5": isOdd && isLast,
            })}
            key={item.slug}
          >
            <Container>
              <Project
                cardBodyClassName={isOdd && "bg-body-tertiary"}
                data={item}
              />
            </Container>
          </div>
        );
      })}
    </Fragment>
  );
};

Projects.propTypes = {
  data: PropTypes.instanceOf(I.List).isRequired,
};

export default Projects;
