import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import Container from "../components/Container";
import ErrorPage from "../pages/ErrorPage";
import { ErrorDataPropType } from "../propTypes";

const ErrorContainer = ({ data, sentryEventId }) => (
  <Fragment>
    <ErrorPage data={data} />
    {sentryEventId ? (
      <Container>
        <button
          className="btn btn-danger"
          onClick={() => Sentry.showReportDialog({ eventId: sentryEventId })}
        >
          Report Feedback via Sentry
        </button>
      </Container>
    ) : null}
  </Fragment>
);

ErrorContainer.propTypes = {
  data: ErrorDataPropType.isRequired,
  sentryEventId: PropTypes.string,
};

export default ErrorContainer;
