import * as Sentry from "@sentry/react";
import React, { Component, Fragment } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { ErrorDataRecord, toErrorDataRecord } from "react-sadness";

import ErrorContainer from "./ErrorContainer";

import { FRONTEND_TITLE } from "../constants";
import CVPage from "../pages/CVPage";
import ErrorPage from "../pages/ErrorPage";
import LandingPage from "../pages/LandingPage";
import LinksPage from "../pages/LinksPage";
import ProjectsPage from "../pages/ProjectsPage";
import TalksPage from "../pages/TalksPage";
import { SENTRY_DSN } from "../settings";

const ERROR_DOES_NOT_EXIST = new ErrorDataRecord({
  title: "Not found",
  detail: (
    <Fragment>
      <p>
        Requested page not found. Maybe the page you accessed is no longer a
        part of <Link to="/">{FRONTEND_TITLE}</Link> personal web-site.
      </p>

      <p>
        Feel free to contact me via{" "}
        <a href="mailto:iam@igordavydenko.com">email</a> or{" "}
        <a href="https://t.me/playpauseandstop">Telegram</a> if you believe
        requested page is not accessible cause of the error.
      </p>
    </Fragment>
  ),
});

const ERROR_SERVER_ERROR = new ErrorDataRecord({
  title: "Server error",
  detail: (
    <Fragment>
      <p>
        Requested page generated a server error. Maybe{" "}
        <Link to="/">{FRONTEND_TITLE}</Link> personal web-site in the middle of
        update and will be back online soon.
      </p>

      <p>
        {" "}
        In any case feel free to contact me via{" "}
        <a href="mailto:iam@igordavydenko.com">email</a> or{" "}
        <a href="https://t.me/playpauseandstop">Telegram</a> with details about
        the error.
      </p>
    </Fragment>
  ),
});

export default class AppContainer extends Component {
  state = {
    errorData: null,
    sentryEventId: null,
  };

  static getDerivedStateFromError(err) {
    return { errorData: toErrorDataRecord(err, { title: "UI Error" }) };
  }

  componentDidCatch(error, errorInfo) {
    if (SENTRY_DSN) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ sentryEventId: eventId });
      });
    }
  }

  render() {
    const { errorData, sentryEventId } = this.state;
    if (errorData) {
      return <ErrorContainer data={errorData} sentryEventId={sentryEventId} />;
    }

    return (
      <Switch>
        <Route component={LandingPage} exact path="/" />

        <Route component={CVPage} exact path="/cv/" />

        <Route component={LinksPage} exact path="/links/" />
        <Route
          exact
          path="/links.html"
          render={() => <Redirect to="/links/" />}
        />

        <Route component={ProjectsPage} exact path="/projects/" />
        <Route
          exact
          path="/projects.html"
          render={() => <Redirect to="/projects/" />}
        />

        <Route component={TalksPage} exact path="/talks/" />
        <Route
          exact
          path="/talks.html"
          render={() => <Redirect to="/talks/" />}
        />

        <Route
          exact
          path="/404/"
          render={() => <ErrorPage data={ERROR_DOES_NOT_EXIST} />}
        />
        <Route
          exact
          path="/500/"
          render={() => <ErrorPage data={ERROR_SERVER_ERROR} />}
        />
        <Route render={() => <ErrorPage data={ERROR_DOES_NOT_EXIST} />} />
      </Switch>
    );
  }
}
