import "bootstrap";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { mount, SadnessProvider } from "react-sadness";

import "./highlight";
import api from "./api";
import ScrollToTop from "./components/ScrollToTop";
import AppContainer from "./containers/AppContainer";
import { enableSentry } from "./settings";
import { getAutoTheme, handleThemeChange, setTheme } from "./themes";

enableSentry();

setTheme(getAutoTheme());
handleThemeChange();

mount(
  <SadnessProvider axios={api}>
    <BrowserRouter>
      <ScrollToTop>
        <AppContainer />
      </ScrollToTop>
    </BrowserRouter>
  </SadnessProvider>,
  document.getElementById("igordavydenko-frontend"),
);
