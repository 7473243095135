import * as I from "immutable";
import { DateTime as LuxonDateTime } from "luxon";

export const TalkEventRecord = new I.Record(
  {
    title: null,
    date: null,
    url: null,
  },
  "TalkEventRecord",
);

export const TalkLinkRecord = new I.Record(
  {
    url: null,
    label: null,
  },
  "TalkLinkRecord",
);

export const TalkRecord = new I.Record(
  {
    slug: null,
    title: null,
    description: null,
    isHighlighted: false,
    event: new TalkEventRecord(),
    speakerDeck: null,
    slides: null,
    video: null,
  },
  "TalkRecord",
);

export const toTalkEventRecord = (data) =>
  new TalkEventRecord({
    ...data,
    date: LuxonDateTime.fromISO(data.date),
  });

export const toTalkLinkRecord = (data) => new TalkLinkRecord(data);

export const toTalkRecord = (data) =>
  new TalkRecord({
    ...data,
    event: toTalkEventRecord(data && data.event),
    speakerDeck:
      data && data.speakerdeck ? toTalkLinkRecord(data.speakerdeck) : null,
    slides: data && data.slides ? toTalkLinkRecord(data.slides) : null,
    video: data && data.video ? toTalkLinkRecord(data.video) : null,
  });
