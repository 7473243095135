import * as I from "immutable";
import React from "react";
import { Response, useRequest } from "react-sadness";

import Container from "../components/Container";
import Page, { getPageProps } from "../components/Page";
import Projects from "../components/Projects";
import { toProjectRecord } from "../records/Project";

import ogImage from "../../public/images/ogimage-projects.jpg";

const toList = (data) => new I.List(data.map(toProjectRecord));

const ProjectsPage = () => {
  const { response } = useRequest("/projects", {
    responseDataConverter: toList,
  });
  return (
    <Page
      {...getPageProps("Open Source Projects")}
      description={`
An author of aiohttp-middlewares, rororo, Flask-And-Redis, react-sadness &
several other open source projects. Igor Davydenko also contributed to some
other popular open source projects such as aiohttp & typeshed.
`}
      name="projects"
      ogDescription="An author of aiohttp-middlewares, rororo, Flask-And-Redis, react-sadness & several other open source projects."
      ogImage={ogImage}
    >
      <Response
        data={response}
        render={({ content, stage }) => {
          if (stage === "renderResponseData") {
            return content;
          }
          return <Container>{content}</Container>;
        }}
      >
        {(projects) => <Projects data={projects} />}
      </Response>
    </Page>
  );
};

export default ProjectsPage;
