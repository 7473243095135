import axios from "axios";
import { isFormData } from "axios/lib/utils";
import { v4 as uuidv4 } from "uuid";

import { BACKEND_URL } from "./settings";

export const camelCase = (value) =>
  value.replace(/_([a-z])/g, (item) => item[1].toUpperCase()).replace("_", "");

export const isObject = (value) => {
  const type = typeof value;
  return value !== null && (type === "object" || type === "function");
};

export const snakeCase = (value) =>
  value.replace(/([A-Z])/g, (item) => `_${item[0].toLowerCase()}`);

export const transformKeys = (data, transformer) => {
  if (isFormData(data)) {
    return data;
  }

  let transformed;
  if (Array.isArray(data)) {
    transformed = data.map((item) => transformKeys(item, transformer));
  } else if (isObject(data)) {
    transformed = {};
    Object.keys(data).forEach((key) => {
      transformed[transformer(key)] = transformKeys(data[key], transformer);
    });
  }

  return transformed || data;
};

const api = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "X-Client-UID": uuidv4(),
    "X-Requested-With": "XMLHttpRequest",
  },
  responseType: "json",
  timeout: 30000,
  transformRequest: [
    (data) => transformKeys(data, snakeCase),
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => transformKeys(data, camelCase),
  ],
});

export default api;
