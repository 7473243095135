import PropTypes from "prop-types";
import React from "react";

const ProjectBadge = ({ data, title, url }) => (
  <a className="ms-2 ms-lg-3" href={url}>
    <img alt={title} src={`https://img.shields.io/${data}`} />
  </a>
);

ProjectBadge.propTypes = {
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ProjectBadge;
