import AnchorJS from "anchor-js";
import React, { useEffect } from "react";

import Page, { getPageProps } from "../components/Page";

const LinksPage = () => {
  useEffect(() => {
    new AnchorJS().add("h2");
  }, []);

  return (
    <Page
      {...getPageProps("Links")}
      description="Most interesting links & social accounts of Igor Davydenko."
      isReady
      hasContainer
      name="links"
    >
      <p className="mb-5">
        As many of you, I have decent online presence. Below, I collected the
        most interesting links & social profiles of myself. The list for sure is
        not complete, as why it should, but content there should be enough to
        get a better understanding of who is Igor Davydenko.
      </p>

      <h2 className="my-3">Social &amp; Messengers</h2>
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://bsky.app/profile/igordavydenko.com">
              @igordavydenko.com
            </a>
          </h3>
          <p>Bluesky profile</p>
        </div>
        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://t.me/playpauseandstop">@playpauseandstop</a>
          </h3>
          <p>Telegram</p>
        </div>
      </div>

      <h2 className="my-3">Development</h2>
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://github.com/playpauseandstop">@playpauseandstop</a>
          </h3>
          <p>GitHub profile</p>
        </div>
      </div>

      <h2 className="my-3">Music &amp; Movies</h2>
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://www.last.fm/user/Loki1zI/">Loki1zI</a>
          </h3>
          <p>
            Yeah, Last.fm is not dead. And here is full list of music I’ve
            listened to
          </p>
        </div>

        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://www.imdb.com/user/ur16703480/watchlist">IMDB</a>
          </h3>
          <p>Movies, I’ve watched and rated</p>
        </div>

        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://bandcamp.com/playpauseandstop">Bandcamp</a>
          </h3>
          <p>I’m buying the music I like. It aint much, but it’s honest work</p>
        </div>

        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://www.songkick.com/users/playpauseandstop">
              SongKick
            </a>
          </h3>
          <p>Check music gigs I’ve attended or plan to</p>
        </div>
      </div>

      <h2 className="my-3">Sports</h2>
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 my-3">
          <h3 className="h4">
            <a href="https://www.strava.com/athletes/igor_davydenko">Strava</a>
          </h3>
          <p>💪 🏃 🚲</p>
        </div>
      </div>

      <p className="mt-5">
        I have profiles in other social networks as well, so in case you’ll run
        into an <i>Igor Davydenko</i> or <code>playpauseandstop</code> profile
        somewhere &mdash; big chance that it’ll be me.
      </p>

      <p className="my-0 py-3">
        And yes, my email address is:{" "}
        <a href="mailto:iam@igordavydenko.com">iam@igordavydenko.com</a>. Feel
        free to say <b>Hi!</b> if you’re share same interests or would like to
        talk with me.
      </p>
    </Page>
  );
};

export default LinksPage;
