import React from "react";
import { Link } from "react-router-dom";

import Container from "../components/Container";
import Page from "../components/Page";
import {
  FRONTEND_DESCRIPTION,
  FRONTEND_FULL_TITLE,
  FRONTEND_TITLE,
} from "../constants";

const LandingPage = () => (
  <Page
    description={`Personal site of ${FRONTEND_TITLE}, ${FRONTEND_DESCRIPTION}.`}
    isReady
    ogTitle={FRONTEND_TITLE}
    name="landing"
    title={FRONTEND_FULL_TITLE}
  >
    <div className="d-flex min-vh-100 align-items-center">
      <Container>
        <h1 className="fw-bolder">Igor Davydenko 🇺🇦</h1>
        <h2 className="mb-0">
          <span className="fw-bolder">Ukrainian</span>.{" "}
          <a href="https://www.python.org/">Python</a> web developer. Passionate
          about creating web applications for real world, giving back to open
          source, and sharing experience.
        </h2>
      </Container>
    </div>

    <div className="bg-body-tertiary">
      <Container className="py-5">
        <div className="my-5">
          <h3 className="mb-3">Present</h3>
          <p>
            Starting from <i>May 2022</i>, I’m working as{" "}
            <b>Backend Team/Tech Lead</b> at{" "}
            <a href="https://portside.co">Portside, Inc.</a> My daily routine
            includes using Python and{" "}
            <a href="https://www.djangoproject.com">Django</a> for developing
            OpenAPI 3 or GraphQL backends for various applications in Portside,
            Inc. portfolio, such as:
          </p>
          <ul>
            <li>
              <a href="https://portside.co/product/business-aviation-aircraft-owner-portal">
                Owner Portal
              </a>
            </li>
            <li>
              <a href="https://portside.co/product/hotel-car-reservations">
                Trip Assist
              </a>
            </li>
          </ul>
          <p>
            At the same time, thanks to my previous experience, I’m working
            closely with similarly passionate Python developers in order to
            reach business goals and to grow their potential. Years in software
            engineering taught me valuable lessons, which I’m sharing with my
            colleagues with joy and humility.
          </p>
        </div>

        <hr className="my-5" />

        <div className="my-5">
          <h3 className="mb-3">Past</h3>
          <p>
            For the last 16 years I was a part of many engineering teams, most
            notably of: <a href="https://42coffeecups.com">42 Coffee Cups</a>,
            oDesk PS, <a href="http://getgoing.com/">GetGoing Inc.</a>, ezhome
            Inc., <a href="https://evo.company">EVO</a>,{" "}
            <a href="https://lifestreet.com">LifeStreet Media</a>, and{" "}
            <a href="https://www.ibhsolutions.com">IBH Solutions</a>. During
            that time I’ve met and worked with many great engineers that helped
            me to become a better engineer and a better person. They have shared
            with me their thoughts, ideas, and solutions, which allowed me to
            better understand the complexity of software engineering and, as a
            result, motivated me to teach more & more technologies to solve my
            outstanding and not so tasks.
          </p>
          <p>
            At the same time, that communication with other engineers resulted
            in a will to share my knowledge with others via{" "}
            <Link to="/talks/">talks</Link>, and{" "}
            <Link to="/projects/">my open source projects</Link> such as{" "}
            <a href="https://github.com/playpauseandstop/aiohttp-middlewares">
              aiohttp-middlewares
            </a>{" "}
            or <a href="https://github.com/playpauseandstop/rororo">rororo</a>.
            I’ve{" "}
            <a href="https://igordavydenko.com/talks/de-pycon-2018/">
              talked much about Python type annotations
            </a>{" "}
            and how they make code more maintainable and developer experience
            more seamless. I’ve{" "}
            <a href="https://ep2015.europython.eu/conference/talks/asyncio-stack-reactjs-or-development-on-the-edge">
              invited Python developers to use Python asyncio stack back in June
              2015
            </a>
            , as well as described my experience with GraphQL, OpenAPI 3, and
            other technologies. Full list of <Link to="/talks/">my talks</Link>{" "}
            and <Link to="/projects/">open source projects</Link> available on
            my site.
          </p>
        </div>
      </Container>
    </div>

    <div>
      <Container className="py-5">
        <div className="my-5">
          <div style={{ backgroundColor: "#0057B8", height: "1rem" }}></div>
          <div style={{ backgroundColor: "#FFD700", height: "1rem" }}></div>
          <p className="fw-bold mt-5">
            I was born in Kyiv, Ukraine, but for the last 7 years am living
            outside of my hometown and my home country. At the moment Ukraine is
            defending itself against the Russian invasion and I ask you to
            support the efforts of non-profit organizations such as{" "}
            <a href="https://savelife.in.ua/en/">Come Back Alive</a>,{" "}
            <a href="https://prytulafoundation.org/en">
              Serhiy Prytula Charity Foundation
            </a>
            , and many others in order to help Ukrainians save their lives,
            identity, and country.
          </p>
          <p>
            I have a dog, beagle, 7 years old, named after <b>LeBron</b> James,
            and am a passionate traveler. For the last 13 years I have visited
            and, as said above, lived in many places around the world with my
            dog. I have strong feeling, that traveling across the world helps me
            being a better human, as I have been learning how people live,
            think, and communicate in many different situations and conditions.
          </p>
          <p>
            My goal is to visit about 3 new countries every year, and if you
            interested in having me at PyCon conference in your country, please
            send me <a href="mailto:iam@igordavydenko.com">an email</a>.
          </p>
        </div>
      </Container>
    </div>

    <div className="bg-body-tertiary">
      <Container className="py-5">
        <div className="my-5">
          My other interests include: watching American football (my favorite
          team is{" "}
          <a href="https://www.clevelandbrowns.com/">Cleveland Browns</a>),{" "}
          <a href="https://www.last.fm/user/Loki1zI">
            listening variety of music
          </a>
          ,{" "}
          <a href="https://www.songkick.com/users/playpauseandstop">
            attending gigs of my favorite artists
          </a>
          , riding my scooter, <i>Junak Vintage 125</i>, and doing workouts.
        </div>

        <div className="my-5">
          Thanks for reading this, and to contact me please use{" "}
          <a href="mailto:iam@igordavydenko.com">an email</a>, or{" "}
          <a href="https://t.me/playpauseandstop">Telegram</a>.
        </div>
      </Container>
    </div>
  </Page>
);

export default LandingPage;
