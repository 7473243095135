import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { TalkLinkRecord } from "../records/Talk";

const TalkLink = ({ className, children, data }) => {
  if (!data) {
    return null;
  }

  return (
    <a className={classnames("card-link", className)} href={data.url}>
      {children}
      {data.label ? ` ${data.label}` : null}
    </a>
  );
};

TalkLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  data: PropTypes.instanceOf(TalkLinkRecord),
};

export default TalkLink;
