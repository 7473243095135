import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Container = ({ className, children }) => (
  <div className={classnames("container-lg", className)}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Container;
