import classnames from "classnames";
import hljs from "highlight.js/lib/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Markdown from "react-markdown";

import ProjectBadges from "./ProjectBadges";
import {
  getGithubUrl,
  getNpmUrl,
  getPypiUrl,
  getReadthedocsUrl,
  hasLinks,
  ProjectRecord,
} from "../records/Project";

const Project = ({ cardBodyClassName, data }) => {
  useEffect(() => {
    Array.prototype.slice
      .call(document.querySelectorAll("pre code"))
      .forEach((item) => {
        hljs.highlightBlock(item);
      });
  }, [data]);

  return (
    <div className="card project-card border-0 p-0">
      <div className={classnames("card-body", "p-0", cardBodyClassName)}>
        <h2 className="card-title" id={data.slug}>
          {data.title}
          <ProjectBadges data={data} />
        </h2>

        <div className="my-3">
          <Markdown>{data.description}</Markdown>
        </div>

        {data.code ? (
          <div className="my-5 d-none d-md-block small">
            <h3 className="my-3 h4">Usage</h3>
            <Markdown>{data.code}</Markdown>
          </div>
        ) : null}

        {hasLinks(data) ? (
          <div className="my-3">
            {data.github ? (
              <a className="card-link" href={getGithubUrl(data)}>
                GitHub
              </a>
            ) : null}
            {data.pypi ? (
              <a className="card-link" href={getPypiUrl(data)}>
                PyPI
              </a>
            ) : null}
            {data.npm ? (
              <a className="card-link" href={getNpmUrl(data)}>
                NPM
              </a>
            ) : null}
            {data.readthedocs ? (
              <a className="card-link" href={getReadthedocsUrl(data)}>
                <span className="d-sm-none">Docs</span>
                <span className="d-none d-sm-inline">Documentation</span>
              </a>
            ) : null}
            {data.demo ? (
              <a className="card-link" href={data.demo}>
                Demo
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Project.propTypes = {
  cardBodyClassName: PropTypes.string,
  data: PropTypes.instanceOf(ProjectRecord).isRequired,
};

export default Project;
