import React from "react";
import { ErrorData } from "react-sadness";

import Container from "../components/Container";
import Debug from "../components/Debug";
import Page, { getPageProps } from "../components/Page";
import { ErrorDataPropType } from "../propTypes";
import { isProduction } from "../settings";

const getErrorTitle = ({ title }) => title || "Error";

const ErrorPage = ({ data }) => (
  <Page
    {...getPageProps(getErrorTitle(data), false)}
    description="Error page. Nothing to see here"
    hasFooterTopLink={false}
    isReady
    name="error"
  >
    <Container className="my-5">
      <ErrorData data={data} />
    </Container>

    {!isProduction() ? (
      <div className="bg-tomorrow-night text-tomorrow-night my-5">
        <Container className="py-5">
          <Debug />
        </Container>
      </div>
    ) : null}
  </Page>
);

ErrorPage.propTypes = {
  data: ErrorDataPropType.isRequired,
};

export default ErrorPage;
