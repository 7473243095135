/**
 * Control color themes for the web-site.
 */

export const MEDIA_QUERY_PREFERS_DARK_THEME = "(prefers-color-scheme: dark)";

export const isDarkTheme = () =>
  window.matchMedia(MEDIA_QUERY_PREFERS_DARK_THEME).matches;

export const getAutoTheme = () => (isDarkTheme() ? "dark" : "light");

export const setTheme = (theme) => {
  document.documentElement.setAttribute("data-bs-theme", theme);
};

export const handleThemeChange = () => {
  window
    .matchMedia(MEDIA_QUERY_PREFERS_DARK_THEME)
    .addEventListener("change", () => {
      setTheme(getAutoTheme());
    });
};
