import PropTypes from "prop-types";
import React from "react";

import Container from "./Container";
import LandingLink from "./LandingLink";
import { FRONTEND_TITLE } from "../constants";

const Header = ({ children }) => (
  <Container className="py-5">
    <h1>
      <span className="d-none d-print-inline">{FRONTEND_TITLE} ·</span>
      <small className="d-print-none text-muted">
        <LandingLink className="text-muted" /> ›
      </small>{" "}
      {children}
    </h1>
  </Container>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
