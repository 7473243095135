import PropTypes from "prop-types";
import React, { Fragment } from "react";

import ProjectBadge from "./ProjectBadge";
import { getNpmUrl, getPypiUrl, ProjectRecord } from "../records/Project";

const ProjectBadges = ({ data }) => (
  <Fragment>
    {data.pypi ? (
      <Fragment>
        <ProjectBadge
          data={`pypi/v/${data.pypi}`}
          title={data.title}
          url={getPypiUrl(data)}
        />
        <ProjectBadge
          data={`pypi/l/${data.pypi}`}
          title="License"
          url={getPypiUrl(data)}
        />
      </Fragment>
    ) : null}
    {data.npm ? (
      <Fragment>
        <ProjectBadge
          data={`npm/v/${data.npm}`}
          title={data.title}
          url={getNpmUrl(data)}
        />
        <ProjectBadge
          data={`npm/l/${data.npm}`}
          title="License"
          url={getNpmUrl(data)}
        />
      </Fragment>
    ) : null}
  </Fragment>
);

ProjectBadges.propTypes = {
  data: PropTypes.instanceOf(ProjectRecord).isRequired,
};

export default ProjectBadges;
