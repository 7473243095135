import * as Sentry from "@sentry/react";

const API_URI = "/api/";

const ensureApiUrl = (backendUrl) => {
  if (!backendUrl) {
    return API_URI;
  }
  if (backendUrl.endsWith(API_URI)) {
    return backendUrl;
  }
  return backendUrl.endsWith("/")
    ? `${backendUrl}${API_URI.slice(1)}`
    : `${backendUrl}${API_URI}`;
};

export const BACKEND_URL = ensureApiUrl(process.env.BACKEND_URL);
export const FRONTEND_URL = process.env.FRONTEND_URL;

export const SENTRY_DSN =
  process.env.FRONTEND_SENTRY_DSN || process.env.SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.NODE_ENV;
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
export const SENTRY_TRACING_SAMPLE_RATE =
  process.env.SENTRY_TRACING_SAMPLE_RATE;

export const enableSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release: SENTRY_RELEASE,
      tracingSampleRate: SENTRY_TRACING_SAMPLE_RATE
        ? parseFloat(SENTRY_TRACING_SAMPLE_RATE) || 0.0
        : 0.0,
    });
  }
};

export const isProduction = () => process.env.NODE_ENV === "production";
