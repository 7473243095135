import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SadnessReady } from "react-sadness";

import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";

import {
  FRONTEND_DESCRIPTION,
  FRONTEND_FULL_TITLE,
  FRONTEND_TITLE,
} from "../constants";
import { FRONTEND_URL } from "../settings";

import ogImageDefault from "../../public/images/ogimage-default.jpg";

export const getPageProps = (pageTitle, hasHeader = true) => ({
  header: hasHeader ? pageTitle : null,
  ogTitle: `${FRONTEND_TITLE} · ${pageTitle}`,
  title: `${pageTitle}. ${FRONTEND_FULL_TITLE}`,
});

const Page = ({
  children,
  description,
  hasContainer,
  hasFooterTopLink,
  header,
  isReady,
  name,
  ogDescription,
  ogImage,
  ogTitle,
  title,
}) => {
  const location = useLocation();

  const pageUrl = `${FRONTEND_URL}${location.pathname}`;
  const ogImageUrl = `${FRONTEND_URL}${ogImage}`;

  return (
    <SadnessReady force={isReady}>
      <Helmet>
        <title>{title}</title>

        <meta name="description" content={description} />

        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:alt" content={ogTitle} />

        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image:src" content={ogImageUrl} />

        <link href={pageUrl} rel="canonical" />
      </Helmet>

      <div className={`page-${name}`}>
        {header ? <Header>{header}</Header> : null}
        {hasContainer ? <Container>{children}</Container> : children}
      </div>

      <Footer hasTopLink={hasFooterTopLink} />
    </SadnessReady>
  );
};

Page.defaultProps = {
  hasFooterTopLink: true,
  isReady: false,
  ogDescription: `${FRONTEND_DESCRIPTION}. GitHub: @playpauseandstop. Bluesky: @igordavydenko.com`,
  ogImage: ogImageDefault,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  hasContainer: PropTypes.bool,
  hasFooterTopLink: PropTypes.bool,
  header: PropTypes.node,
  isReady: PropTypes.bool,
  name: PropTypes.string.isRequired,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
