import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import Container from "./Container";
import LandingLink from "./LandingLink";
import { isDarkTheme } from "../themes";

const Footer = ({ className, hasTopLink }) => (
  <div className={classnames(className, "d-print-none", "small")}>
    <Container className="py-5">
      <p className={isDarkTheme() && "text-muted"}>
        <LandingLink className={isDarkTheme() && "text-muted"} />
        <br />
        2014 &mdash; 2024
      </p>

      <hr />

      <ul className="list-inline">
        <li className="list-inline-item me-3 me-lg-4">
          <Link to="/cv/">CV</Link>
        </li>
        <li className="list-inline-item me-3 me-lg-4">
          <Link to="/talks/">Talks</Link>
        </li>
        <li className="list-inline-item me-3 me-lg-4">
          <Link to="/projects/">Open Source Projects</Link>
        </li>
        <li className="list-inline-item me-3 me-lg-4">
          <Link to="/links/">Links</Link>
        </li>
        <li className="list-inline-item me-3 me-lg-4">
          <a href="https://github.com/playpauseandstop">GitHub</a>
        </li>
        <li className="list-inline-item">
          <a href="https://bsky.app/profile/igordavydenko.com">Bluesky</a>
        </li>
      </ul>

      {hasTopLink ? (
        <p className="mt-3">
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to="#top"
          >
            Back to top
          </Link>
        </p>
      ) : null}
    </Container>
  </div>
);

Footer.defaultProps = {
  hasTopLink: true,
};

Footer.propTypes = {
  className: PropTypes.string,
  hasTopLink: PropTypes.bool,
};

export default Footer;
