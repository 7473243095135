import React from "react";
import { Link } from "react-router-dom";

import Page, { getPageProps } from "../components/Page";
import { FRONTEND_DESCRIPTION } from "../constants";

import ogImage from "../../public/images/ogimage-cv.jpg";

const DT_CLASS_NAME = "mt-3 h5";
const DT_CLASS_NAME_FIRST_CHILD = "mt-3 h5";
const HR_CLASS_NAME = "my-3";

const CVPage = () => (
  <Page
    {...getPageProps("CV")}
    description={`CV of ${FRONTEND_DESCRIPTION} that likes making web applications for real world.`}
    hasContainer
    isReady
    name="cv"
    ogDescription={`View and download CV of ${FRONTEND_DESCRIPTION}.`}
    ogImage={ogImage}
  >
    <div className="d-print-none mb-3">
      <a download href="../files/Igor Davydenko. CV.pdf">
        Download
      </a>{" "}
      &middot;{" "}
      <a
        href="#"
        onClick={() => {
          window.print();
        }}
      >
        Print
      </a>
      <hr />
    </div>

    <h2>
      <b>Ukrainian</b>. Python developer
    </h2>
    <p className="my-3">
      Male. 36 years old. Born at Aug 15th, 1987 in Kyiv, Ukraine. Currently
      lives in Cracow, Poland. Lives with a dog, beagle, 7 years old, LeBron.
    </p>

    <hr className={HR_CLASS_NAME} />

    <h3>Education</h3>
    <dl>
      <dt className={DT_CLASS_NAME_FIRST_CHILD}>
        <b>2004 &ndash; 2006</b>
      </dt>
      <dd>
        <b>Unfinished</b>. National University of Ukraine &quot;Kyiv Polytechnic
        Institute&quot;
      </dd>
    </dl>

    <hr className={HR_CLASS_NAME} />

    <h3>Skills</h3>
    <dl>
      <dt className={DT_CLASS_NAME_FIRST_CHILD}>
        <b>Python 3</b>
      </dt>
      <dd>
        Using Python for work needs from <b>2007</b>. Has a strong knowledge in
        Python 3, asyncio stack & type annotations. Knows how to port Python 2
        code to Python 3
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Python web-frameworks</b>
      </dt>
      <dd>
        Using Django, Flask, and aiohttp for building backends for different web
        applications. For last 3 years using aiohttp as main web framework
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>JavaScript, CSS, HTML5</b>
      </dt>
      <dd>
        Built first web site in <b>2002</b>, since actively improve my
        experience with JavaScript, CSS, and HTML. No afraid in using vanilla
        JavaScript without Babel
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>React</b>
      </dt>
      <dd>
        Knows pros &amp; cons of React and as result using it on daily basis for
        creating modern web user interfaces for real world projects. Using React
        hooks for all good things
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Data Storages</b>
      </dt>
      <dd>
        Using PostgreSQL as a data storage for most of the projects &amp; Redis
        as a cache layer. In same time has a production experience with:
        Memcached, CouchBase, Firebase, Kafka, DynamoDB
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Infrastructure</b>
      </dt>
      <dd>
        Has a strong feeling, that reliable infrastructure is a must for any
        modern web application. Knows how to deploy web applications, and how to
        integrate best dev practices with CI services, such as GitHub Actions,
        Gitlab CI, Circle CI &amp; Jenkins. Deployed applications to AWS,
        DigitalOcean, Heroku, Hetzner Cloud
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Developer Experience</b>
      </dt>
      <dd>
        Using git for source management all my projects. Working between GitHub
        & Gitlab. Integrating black, prettier (for code formatting needs),
        flake8, mypy, eslint (for lint needs) to every possible project via
        pre-commit hooks. Has a confidence in enforcing code style guides for
        teams to help them perform better &amp; faster
      </dd>
    </dl>

    <hr className={HR_CLASS_NAME} />

    <h3>Career</h3>
    <dl>
      <dt className={DT_CLASS_NAME_FIRST_CHILD}>
        <b>42 Coffee Cups</b> (2008 &ndash; 2011)
      </dt>
      <dd>
        Mostly using Django for developing web applications for many different
        customers. Also been a lead developer of internal tool to manage
        developers within the team.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>oDesk PS</b> (2011 &ndash; 2012)
      </dt>
      <dd>
        Combined Django &amp; Flask for development applications on top of oDesk
        (now Upwork) API. Those applications allowed customers to use oDesk
        freelancers for their needs.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>GetGoing Inc.</b> (2012 &ndash; 2015)
      </dt>
      <dd>
        Took a part in designing &amp; implementing hotels search for GetGoing
        travel startup from the scratch. Heavily used Celery for interaction
        between different system parts, took a part in integrating React at
        frontend instead of old Backbone/Marionette UI.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>ezhome Inc.</b> (2015 &ndash; 2016)
      </dt>
      <dd>
        Been a lead develoer of internal tools for ezhome Inc. managers.
        Provided a React components library, which simplifies development of
        many similarly looked applications for customer, gardener management,
        coordinating gardeners in the field, and find a schedule fastest routes
        for the gardeners. As well as took part in Django backend development
        &amp; interaction with Firebase.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>EVO Company</b> (2016 &ndash; 2018)
      </dt>
      <dd>
        Team Lead of Vchasno project: web application for signing documents that
        conforms Ukrainian laws. Designed and built a backend for storing signed
        data at backend on top of aiohttp. As well as designed blackbox tool to
        integrate Vchasno into accountant software such as 1C.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Poptop</b> (2017 &ndash; 2018)
      </dt>
      <dd>
        Provided consultations for Poptop startup. Optimized Django backend
        performance. Integrated GraphQL into Django backend (via
        graphene-django) and React frontend (via Apollo).
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>LifeStreet Media</b> (2018 &ndash; 2019)
      </dt>
      <dd>
        Developed HTML5 mobile games on top of Phaser framework. Built an UI kit
        with common components to simplify &amp; speedup mobile game development
        process.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Remak A.S.</b> (2018 &ndash; 2020)
      </dt>
      <dd>
        Provided consultations for internal AHU Platform project, an attempt to
        migrate C++ toolset to the web. Using aiohttp at backend and React at
        frontend. Integrated umbrella repository deployment to Hetzner Cloud on
        top of Gitlab CI.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>RxNumerix Inc.</b> (2018 &ndash; 2019)
      </dt>
      <dd>
        Built a demo and MVP for RxNumerix Inc. US startup. Both demo and MVP
        has been built on top of Python 3 & Django at backend & React at
        frontend. Implemented own CI / CD tool to manage demo / MVP deploys on
        top of Python 3 & asyncio stack. Heavily use of Amazon AWS
        infrastructure (Elastic BeansTalk, CloudFront, S3, etc).
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>IBH Solutions</b> (2020 &ndash; 2022)
      </dt>
      <dd>
        Lead process of migrating old assessment management system from third
        party outdated software into modern development stack of Python, Django,
        TypeScript &amp; React. New assessment management software architecture
        allowed to gradually develop various parts of the system such as
        Assessment View, Patient Management System, Reports, etc.
      </dd>

      <dt className={DT_CLASS_NAME}>
        <b>Portside, Inc.</b> (2022 &ndash;{" "}
        <b>
          <i>present</i>
        </b>
        )
      </dt>
      <dd>
        Working as Team / Tech Lead at various projects, which involves using
        Python &amp; Django for developing OpenAPI &amp; GraphQL backend
        services, which helps to manage private jet operations at Owner Portal
        and helps management companies deal with hotels and car bookings at Trip
        Assist.
      </dd>
    </dl>

    <hr className={HR_CLASS_NAME} />

    <h3>Contacts</h3>
    <ul>
      <li>
        Web Site:{" "}
        <Link className="text-tomorrow text-decoration-underline" to="/">
          igordavydenko.com
        </Link>
      </li>

      <li>
        Email:{" "}
        <a
          className="text-tomorrow text-decoration-underline"
          href="mailto:iam@igordavydenko.com"
        >
          iam@igordavydenko.com
        </a>
      </li>

      <li className="mt-3">
        GitHub:{" "}
        <a
          className="text-tomorrow text-decoration-underline"
          href="https://github.com/playpauseandstop"
        >
          @playpauseandstop
        </a>
      </li>

      <li>
        Bluesky:{" "}
        <a
          className="text-tomorrow text-decoration-underline"
          href="https://bsky.app/profile/igordavydenko.com"
        >
          @igordavydenko.com
        </a>
      </li>
    </ul>

    <div className="d-print-none mt-3">
      <hr />
      <a download href="../files/Igor Davydenko. CV.pdf">
        Download
      </a>{" "}
      &middot;{" "}
      <a
        href="#"
        onClick={() => {
          window.print();
          return false;
        }}
      >
        Print
      </a>
    </div>
  </Page>
);

export default CVPage;
