import * as I from "immutable";

export const ProjectRecord = new I.Record(
  {
    slug: null,
    title: null,
    description: null,
    code: null,
    github: null,
    pypi: null,
    npm: null,
    readthedocs: null,
    demo: null,
  },
  "ProjectRecord",
);

export const getGithubUrl = ({ github }) => `https://github.com/${github}`;

export const getNpmUrl = ({ npm }) => `https://www.npmjs.com/package/${npm}`;

export const getPypiUrl = ({ pypi }) => `https://pypi.org/project/${pypi}/`;

export const getReadthedocsUrl = ({ readthedocs }) =>
  `https://${readthedocs}.readthedocs.io`;

export const hasLinks = (data) =>
  data.github || data.pypi || data.npm || data.readthedocs || data.demo;

export const toProjectRecord = (data) => new ProjectRecord(data);
