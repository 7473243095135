import AnchorJS from "anchor-js";
import classnames from "classnames";
import * as I from "immutable";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import Talk from "./Talk";

const Talks = ({ data }) => {
  useEffect(() => {
    new AnchorJS().add("h3.card-title");
  }, [data]);

  return (
    <div className="row align-items-center">
      {data.map((item, idx) => (
        <div
          className={classnames("col-12", {
            "col-md-6": !item.isHighlighted,
          })}
          key={item.slug}
        >
          <Talk
            data={item}
            isFirst={idx === 0}
            isLast={idx === data.count() - 1}
          />
        </div>
      ))}
    </div>
  );
};

Talks.propTypes = {
  data: PropTypes.instanceOf(I.List).isRequired,
};

export default Talks;
