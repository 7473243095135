import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Markdown from "react-markdown";

import TalkLink from "./TalkLink";

import { DATE_FORMAT } from "../constants";
import { TalkRecord } from "../records/Talk";

const Talk = ({ data, isFirst, isLast }) => {
  const { event } = data;
  const hasSpeakerDeckLink = Boolean(data.speakerDeck);

  console.info({ description: data.description });

  return (
    <Fragment>
      <div
        className={classnames("card talk-card bg-tomorrow rounded-0", {
          "my-3": !isFirst,
          "mb-3": isFirst,
        })}
      >
        <div className="card-body">
          <h3 className="card-title" id={data.slug}>
            {data.isHighlighted ? (
              <span className="text-warning">★</span>
            ) : null}{" "}
            {data.slides ? (
              <a className="text-tomorrow" href={data.slides.url}>
                {data.title}
              </a>
            ) : (
              data.title
            )}
          </h3>

          <h4 className="card-subtitle h5 font-weight-normal text-muted">
            <a className="text-muted" href={event.url}>
              {event.title}
            </a>{" "}
            &middot; {event.date.toFormat(DATE_FORMAT)}
          </h4>

          <div className="my-3 card-text">
            <Markdown>{data.description}</Markdown>
          </div>

          <TalkLink className="d-block d-sm-inline" data={data.speakerDeck}>
            Speaker Deck
          </TalkLink>
          <TalkLink
            className={classnames({ "ms-0 ms-sm-3": hasSpeakerDeckLink })}
            data={data.slides}
          >
            Slides
          </TalkLink>
          <TalkLink data={data.video}>Video</TalkLink>
        </div>
      </div>
      {!isLast ? <hr className="d-md-none my-4" /> : null}
    </Fragment>
  );
};

Talk.propTypes = {
  data: PropTypes.instanceOf(TalkRecord).isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default Talk;
