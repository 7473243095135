import React, { Fragment } from "react";

import { EMPTY_VALUE } from "../constants";
import {
  BACKEND_URL,
  FRONTEND_URL,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
} from "../settings";

const Debug = () => (
  <Fragment>
    <h4>Debug Information</h4>
    <p>You see this block as web UI run in non-production mode.</p>

    <h5>Environment</h5>
    <dl className="row">
      <dt className="col-12 col-md-3">
        <code>NODE_ENV</code>
      </dt>
      <dd className="col-12 col-md-9">{process.env.NODE_ENV}</dd>

      <dt className="col-12 col-md-3">
        <code>BACKEND_URL</code>
      </dt>
      <dd className="col-12 col-md-9">
        <a href={BACKEND_URL}>{BACKEND_URL}</a>
      </dd>

      <dt className="col-12 col-md-3">
        <code>FRONTEND_URL</code>
      </dt>
      <dd className="col-12 col-md-9">
        <a href={FRONTEND_URL}>{FRONTEND_URL}</a>
      </dd>

      <dt className="col-12 col-md-3">
        <code>SENTRY_DSN</code>
      </dt>
      <dd className="col-12 col-md-9">{SENTRY_DSN || EMPTY_VALUE}</dd>

      <dt className="col-12 col-md-3">
        <code>SENTRY_ENVIRONMENT</code>
      </dt>
      <dd className="col-12 col-md-9">{SENTRY_ENVIRONMENT || EMPTY_VALUE}</dd>

      <dt className="col-12 col-md-3">
        <code>SENTRY_RELEASE</code>
      </dt>
      <dd className="col-12 col-md-9">{SENTRY_RELEASE || EMPTY_VALUE}</dd>
    </dl>

    <p>
      Open <b>Console</b> to see more verbose information about the error.
    </p>
  </Fragment>
);

export default Debug;
